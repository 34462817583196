.profile_page {
    background-color: rgb(240, 240, 240);
    position: relative;
    height: 95vh;
}

.profile_holder {
    background-color: rgb(202, 202, 202);
    width: 170vh;
    min-width: 100vh;
    margin: auto;
    height:110ch;
}

.profile_header {
    color:rgb(255, 255, 255);
    font-style: italic;
    margin-top: 3px;
    margin-left: 12%;
    font-size: 5em;
}

.prof_delete_acc {
    position: absolute;
    left:49.5%;
    top:11%;
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.prof_delete_acc:hover {
    background-color: rgb(255, 0, 0);
    color: rgb(255, 255, 255);   
}

.profile_info_backing {
    background-color: rgb(184, 184, 184);
    margin-right: 60%;
    margin-top: -40px;
    margin-left: 4.5%;
    height: 85px;
    border-radius: 20px;
}

.profile_info {
    margin-left: 7%;
    font-size: 18px;
    font-weight: 600;
    color: yellow;
    margin-top: 2%;
}

.profile_forms_holder {
    position:absolute;
    left: 49%;
    top:18%;
    z-index: 99 !important;
}

.prof_change_form {
    position:absolute;
    left: -54%;
    top:-160%;
    margin: auto;
    width: 200%;
    height: 380%;
    text-align: center;
    border-radius: 20px;
    box-shadow: 1px 5px 5px #9c9c9c;
    background-color: rgb(202, 202, 202);
    border: solid rgb(255, 255, 255);
    transition: .5s;
    z-index: 99 !important;
}

.prof_change_form:hover {
    border: solid rgb(0, 195, 255);

}

.button {
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.button:hover {
    background-color: yellow;
    color: rgb(92, 164, 176);
}

.orders_heading {
    margin-left:12%;
    margin-top:3%;
    font-size: 2em;
    color:rgb(255, 255, 255);
    font-style: italic;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.orders_holder {
    position: absolute;
    top: 35%;
    left: 18%;
    height:57%;
    width:42%;
    overflow:auto;
    margin: auto;
    display: grid;
    row-gap: 30px;
    grid-template-columns: auto;
    background-color: rgb(220, 220, 220);
    box-shadow: 3px 3px 15px #232323;
}

.orders_holder:hover {
    outline: solid yellow;
}

.no_history_text {
    color:rgb(123, 123, 123);
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-left: 29%;
}

.reviews_heading {
    position: absolute;
    top: 2%;
    left: 67%;
    font-size: 2em;
    color:rgb(255, 255, 255);
    font-style: italic;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.reviews_holder {
    position: absolute;
    top: 13%;
    left: 62%;
    margin: auto;
    display: grid;
    row-gap: 30px;
    grid-template-columns: auto;
    height:79%;
    width:20%;
    overflow:auto;
    background-color: rgb(220, 220, 220);
    box-shadow: 3px 3px 15px #232323;
}

.reviews_holder:hover {
    outline: solid yellow;
}

@media screen and (max-width: 1200px) {
    .reviews_holder {
        top: 45%;
        height: 50vh;
        left: 52%;
        width: 46vw;
    }

    .orders_holder {
        position: absolute;
        top: 45%;
        left: 1%;
        height:50vh;
        width:50vw;
    }

    .reviews_heading {
        position: relative;
        top: -6.1em;
        left: 50vw;
        font-size: 1.8em;
        z-index: 99 !important;
    }

    .orders_heading {
        position: relative;
        top: -4em;
        left: -5em;
        font-size: 1.8em;
    }

    .profile_info {
        position: relative;
        margin-left: 2%;
        margin-right: 60%;
        font-size: 18px;
        font-weight: 600;
        color: yellow;
    }

    .profile_info_backing {
        position: relative;
        top: -10%;
        width:100vw;
        height: 16%;
        right: 4.5%;
    }

    .button {
        position: relative;
        top: -1em;
        width: 42vw;
        margin-top: 0px;
    }

    .prof_delete_acc {
        position: relative;
        left:50vw;
        top:5.5%;
        width: 40vw;
        z-index: 99 !important;
    }

    .prof_change_form {
        position:relative;
        height: 100vh;
        width: 100vw;
        top:-12.5em;
        left: -50%;
        
    }

    .profile_header {
        position: relative;
        color:rgb(255, 255, 255);
        font-style: italic;
        margin-left: 18vw;
    }
}