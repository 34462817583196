.search_form {
    position: relative;
    margin: auto;
    text-align: center;
}

.search_bar {
    position: relative;
    top: -2.5em;
    height: 3vh;
    font-size: 130%;
    font-weight: 700;
    width: 20%;
}

.vendor_filter {
    position: relative;
    top: -7em;
    height: 4%;
    left: 25%;
    padding:.5em .5em;
    width:9%;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: #ffffff;
    background-color: #75a2bc;
    border: none;
    box-shadow: 3px 3px 8px rgb(40, 157, 207);
    cursor: pointer;
    border-radius: 50px;
    transition: .2s;
}

.vendor_filter:hover {
    background-color: rgb(0, 14, 68);
}