.panel_holder {
    position: absolute;
    top: 4em;
    display: grid;
    row-gap: 2%;
    grid-template-columns: 25% ;
    height:93vh;
    width:15vw;
    overflow:auto;
}

.panel_holder::-webkit-scrollbar {
    display: none;
}

.p_review_holder {
    width: 400%;
    border-radius: 30px;
    background-color: rgb(202, 202, 202);
    box-shadow: 0px 1px 1px rgb(147, 147, 147);
    transition: .3s;
}

.p_review_holder:hover {
    background-color: rgb(210, 238, 255);
    box-shadow: -1px 8px 12px rgb(168, 168, 168);
}

.rating {
    font-size: 1.2vw;
    font-weight: 800;
    margin: auto;
    margin-top: 1%;
    padding-bottom: 4px;
    margin-bottom: 1%;
    width: 30%;
    color: rgb(201, 233, 255);
    background-color: rgb(38, 164, 255);
    border-radius: 45%;
    border: solid rgb(226, 248, 255);
    text-align: center;
}

.image {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
    box-shadow: 0px 2px 10px rgb(79, 100, 107);
    cursor: pointer;
    transition: 200ms;
}

.image:hover {
    outline: solid yellow;
    border-radius: 1em;
}

.product_name {
    margin:auto;
    margin-top: 5%;
    text-align: center;
    width: 90%;
    font-size: 1vw;
    font-weight: 700;
    color: rgb(50, 64, 69);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.description {
    margin: auto;
    margin-bottom: 10%;
    padding: 3%;
    text-align: center;
    width: 90%;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: .9vw;
    background-color: rgb(50, 64, 69);
    border-radius: 20px;
}

@media screen and (max-width: 800px) {

    .panel_holder {
        width:20vw;
        top: 15vh;
    }

    .p_review_holder {
        border-radius: 0;
    }

    .image {
        width: 100%;
    }

    .product_name {
        width: 100%;
        font-size: 1em;
        color: rgb(255, 255, 255);
        background-color: rgb(128, 128, 128);
        border-radius: 0;
    }

    .rating {
        font-size: 1.2em;
        width: 100%;
        border-radius: 0;
        border: none;
    }

    .description {
        width: 100%;
        font-size: .9em;
        border-radius: 0;
    }

}