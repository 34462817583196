.header_logged_out {
    padding-bottom: 10px;
    padding-left: 10px;
    margin-top: 0%;
    margin-bottom: -25px;
    color:rgb(92, 164, 176);
    font-style: italic;
    font-size: 6rem;
    background-color: rgb(255, 255, 255);
    background-image: url("https://thumbs.gfycat.com/AblePassionateAnemoneshrimp-size_restricted.gif");
    background-repeat: no-repeat;
    background-position-y: -150px;
    background-position-x: 0px;
    background-size:750px;
}   

.text_under_header {
    color:rgb(242, 253, 255);
    font-size: 30px;
    font-style: oblique;
    font-weight: 700;
    position: relative;
    margin-left: 30px;
}

.form_holder {
    margin: auto;
    margin-top: 5%;
    width: 50%;
}

.subtext {
    text-align: center;
    margin: auto;
    width: 30%;
    cursor: pointer;
}