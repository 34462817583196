.order_card {
    margin: auto;
    width: 90%;
    color: #ffffff;
    background-color:#576068;
    border-radius: 1em;
    box-shadow: 5px 5px 5px grey;
}

.order_details {
    background-color: #6c7780;
    width: 90%;
    margin: auto;
    padding-left: 1%;
    padding-bottom: 1%;
}