.review_card {
    margin: auto;
    width: 100%;
    color: #ffffff;
    background-color:#84919c;
    border-radius: 10px;
}

.review_text {
    margin-left: 5px;
}

.review_image {
    width:100%
}

.button_remove_review {
    border-radius: 3px;
    letter-spacing: .5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;
    margin-left: 60%;
    border: 0ch;
    background-color: #59626a;
    color: aliceblue;
    cursor: pointer;
}