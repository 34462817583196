.app_holder {
    position: relative;
    top: 0%;
    height: 6.8%;
}

.corner_logo {
    color:rgb(92, 164, 176);
    font-style: italic;
    margin-top: 5px;
    margin-left: 10px;
    width:6%;
    cursor: pointer;
}

.app_container {
    position: absolute;
    height: 100vh;
    width: 100%;
    bottom: 0px;
    background-color:rgb(176, 231, 255);
    width: 100%;
    margin-left: -.4%;
    overflow: hidden;
}

h1, p {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

::placeholder {
    color: rgb(99, 132, 137);
}

::-webkit-input-placeholder {
    text-align: center;
  }

label {
    color:rgb(59, 99, 106);
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    display: inline-block;
    width: 250px;
}

input {
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
    border: 3px solid #bdcfdf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    border-radius: 1em;
}

input:focus {
    border: 3px solid rgb(255, 232, 80);
}

option {
    font-size: 20px;
}

textarea {
    width: 300px;
    height:100px;
    border:3px solid rgb(219, 219, 219);
    box-shadow: 0px 5px 12px rgb(209, 209, 209);
    outline: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

textarea:focus {
    border: 3px solid rgb(255, 232, 80);
}

.button {
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {

    .corner_logo {
        margin-left: 65%;
    }
}