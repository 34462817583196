.form {
    margin: auto;
    width: 30%;
    min-width: 50vh;
    text-align: center;
    background-color: #e3f2ff;
    border-radius: 20px;
    box-shadow: 10px 10px 5px rgb(141, 201, 227);
}

.sub_header {
    color:rgb(92, 164, 176);
    font-style: italic;
    margin-top: 30%;
    margin-left: 10px;
}

.username_holder {
    margin: auto;
    margin-left: 3.5%;
}

.password_backdrop {
    position: relative;
    margin: auto;
    background-color: rgb(160, 208, 228);
    width: 80%;
    border-radius: 20px;
    box-shadow: 5px 5px 3px rgb(136, 175, 192);
    padding-top: 3%;
}

.button {
    border-radius: 1em;
    padding: 1%;
    letter-spacing: .5px;
    padding-left: 30%;
    padding-right: 30%;
    margin-bottom: 3%;
    margin-top: 4%;
    border: 0ch;
    background-color: rgb(60, 75, 77);
    font-size: 1.2em;
    color: aliceblue;
    cursor: pointer;
}

.error_messages {
    position: absolute;
    top: 44%;
    left: 76%;
    transform: translate(-50%, -50%);
    color: #ff5e00;
    font-size: large;
    font-weight: 600;
}

.subtext {
    text-align: center;
    margin: auto;
    padding-bottom: 8px;
    width: 80%;
    cursor: pointer;
}