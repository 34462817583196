.shop_background {
    position: relative;
    height: 100vh;
    background-color: rgb(255, 255, 255);
}

.products_holder {
    position: relative;
    top:-5.9em;
    height: 93vh;
    width:70vw;
    margin: auto;
    display: grid;
    row-gap: 1.5vw;
    grid-template-columns: repeat(4, 1fr);
    overflow:auto;
}

@media screen and (max-width: 1300px) {
    .products_holder {
        grid-template-columns: repeat(3, 1fr);
        height: 100vh;
    }
}

@media screen and (max-width: 800px) {
    .products_holder {
        grid-template-columns: repeat(2, 1fr);
        height: 100vh;
        top: -11%;
        width: 80vw;
        left: 10vw;
    }

    .shop_background {
        position: relative;
        height: 100vh;
        background-color: rgb(255, 255, 255);
    }
}

.products_holder::-webkit-scrollbar {
    display: none;
}

.error_message {
    position:absolute;
    left: 65%;
    top: -1.5%;
    font-size: 25px;
    color: rgb(255, 72, 0);
    font-weight: 700;
    padding: 5px;
    margin-bottom: -5px;
}