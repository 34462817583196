.select_holder {
    position: relative;
    top: -7em;
    height: 4%;
    width: 10%;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: #ffffff;
    background-color: #75a2bc;
    border: none;
    border-radius: 1em;
    box-shadow: 3px 3px 8px rgb(40, 157, 207);
    margin-left: 60%;
    cursor: pointer;
    transition: .2s;
}

.select_holder:hover {
    background-color: rgb(0, 14, 68);
}