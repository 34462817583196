.cart_icon {
    position: fixed;
    background-color: #232323;
    left: 87%;
    top:.3%;
    width:3vw;
    border: solid 3px rgb(255, 255, 255);
    border-radius: 50%;
    cursor: pointer;
    z-index: 99 !important;
}
.cart_icon:hover {
    opacity: 75%;
    transition: .3s;
}

.cart_count {
    position: absolute;
    text-align: center;
    left: 85.9%;
    top:.1%;
    color: #c6c6c6;
    font-size: 100%;
    padding-left: .5%;
    padding-right: .5%;
    font-weight: 700;
    background-color: #3b4b5a;
    border-radius: 50em;
    border: solid #d9edff ;
    z-index: 99 !important;
}

.cart_drop_menu {
    position: fixed;
    z-index: 99 !important;
    background-color:#c0c0c0;
    height: 33%;
    width:15%;
    left: 81%;
    top:5%;
    padding: 3px;
    display: grid;
    row-gap: 20px;
    grid-template-columns: 100%;
    overflow:auto;
    box-shadow: -5px 5px 10px rgb(133, 133, 133);
}

.button_cart_close {
    position: fixed;
    background-color: #232323;
    color:#ffe100 ;
    font-size: 150%;
    display: block;
    margin: auto;
    left: 82.8%;
    width:12%;
    top: 1%;
    height: 4%;
    border: solid 3px #ffffff;
    border-radius: 1em;
    z-index: 99 !important;
    cursor: pointer;
}

.total {
    position: fixed;
    display: inline-block;
    top: 36.5%;
    left: 80.8%;
    background-color: #ffe100;
    width: 15%;
    padding-bottom: .5%;
    padding-top: 2px;
    padding-left: 3px;
    border: solid #232323;
}

.checkout {
    position: fixed;
    top: 37.5%;
    left: 91.5%;
    z-index: 99;
    background-color: #232323;
    color: #ffe100;
    border: solid #ffffff;
    font-size: 150%;
    padding: 12px;
    border-radius: 30px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {

    .cart_icon {
        width:10%;
    }

    .cart_drop_menu {
        position: fixed;
        z-index: 99 !important;
        height: 90vh;
        width:100vw;
        left: 0%;
        top:0%;
        z-index: 120 !important;
    }

    .button_cart_close {
        position: fixed;
        background-color: #232323;
        color:#ffe100 ;
        font-size: 150%;
        width:100vw;
        height: 7vh;
        left: 0;
        top: 0;
    }

    .checkout {
        position: fixed;
        top: 89.5%;
        left: 61%;
        height: 10vh;
        width: 40vw;
        border-radius: 0;
    }

    .total {
        position: fixed;
        bottom: 0;
        height: 8.4vh;
        top: 87%;
        left: 0;
        width: 60vw;
    }
}