.cart_item {
    margin: auto;
    width: 90%;
    text-align: center;
    box-shadow: 0px 5px 10px #9c9c9c;
    background-color: #c6c6c6;
    font-size: 16px;
    font-weight: 600;
    transition: .3s;
}

.cart_item:hover {
    background-color: #ffffff;
    border-radius: 2em;
}

.button {
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}