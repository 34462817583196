.menu_holder {
    background-color: #84919c;
    position: static;
}

.menu_icon {
    position: absolute;
    left:10%;
    top: 18%;
    padding:.5em .5em;
    margin-left:20px;
    width:7.5vw;
    height: -1%;
    text-align: center;
    font-size: 110%;
    font-weight: 600;
    overflow: hidden;
    color: #ffffff;
    background-color: #648092;
    border: none;
    box-shadow: 3px 5px 15px #466070;
    border-radius: 5px;
}   

    .menu_icon:hover {
        transition-duration: .3s;
        background-color: #101820;
    }

.navbar {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10.1%;
    top: 65%;
    padding-right: 1.5em;
    margin-left:20px;
    margin-top: 5px;
    margin-bottom: 5px;
    width:6vw;
    font-size: 100%;
    font-weight: 600;
    overflow: hidden;
    color: #84919c;
    background-color: #3b4b5a;
    border: none;
    box-shadow: 3px 5px 15px;
    z-index: 99 !important;
}

.navbar:hover {
    transition-duration: .3s;
    background-color: #101820;
}

.list_option {
    position: relative;
    margin: center;
    margin-bottom: 25%;
    list-style: none;
    cursor: pointer;
    width:7%;
}

.list_option:hover {
    transition-duration: .3s;
    color: yellow;
}

@media screen and (max-width: 1200px) {

    .menu_icon {
        position: absolute;
        left:-5vw;
        top: -8%;
        height: 8vh;
        padding:.5em .5em;
        width:20%;
        z-index: 110 !important;
    }

    .navbar{
        position: absolute;
        height: 100vh;
        width: 120vw;
        left: -30%;
        top: -15%;
        font-size: 3em;
        z-index: 100 !important;
    }
}

@media screen and (max-width: 800px) {

    .menu_icon {
        position: absolute;
        left:-5vw;
        height: 15vh;
        padding:.5em .5em;
        width:20%;
        z-index: 110 !important;
    }

}