.product_card {
    width: 95%;
    margin: auto;
    text-align: center;
    background-color: #f3f3f3;
    font-size: 1rem;
    box-shadow: 1px 1px 5px rgb(168, 168, 168);
    transition: .5s;
    max-height: 45vh;
}

.product_card:hover {
    transition-duration: 250ms;
    background-color: #ffffff;
    box-shadow: -1px 8px 12px rgb(168, 168, 168);
    overflow: hidden;
}

.product_card_remove {
    width: 95%;
    height: 40vh;
    max-height: 45vh;
    margin: auto;
    text-align: center;
    font-size: 1rem;
    background-color: rgb(0, 0, 0);
    font-size: 18px;
    box-shadow: 0px 2px 10px rgb(168, 168, 168);
    transition-duration: .4s;
    border-radius: 3em;
}

.product_card_remove:hover {
    opacity: 0;
    background-color: #000000;
}

.product_add:hover {
    background-color:#284c6b;
    color: yellow;
}

.product_text {
    font-size: 1.2vw;
}

.product_c_image {
    width: 70%;
    object-fit: cover;
    margin-top: -5%;
    margin-bottom: -8%;
    cursor: pointer;
    border: solid 5px white;
    border-radius: 5px;
    transition: 250ms;
}

.product_c_image:hover {
    border: solid 5px yellow;
    border-radius: 1em;
}

.product_c_image_remove {
    display: inline-block;
    max-width: 60%;
    object-fit: cover;
    margin-top: -10px;
    margin-bottom: -20px;
    cursor: pointer;
    opacity: 50%;
}

.in_cart {
    position: relative;
    top:-30%;
    font-weight: 700;
    font-size: 100%;
    color: #ffe100;
    background-color: #232323;
    cursor: pointer;
}

.product_add {
    line-height: 65%;
    font-size: 1vw;
    font-weight: 700;
    border-radius: 1em;
    padding:3%;
    margin-bottom: 5%;
    border: solid #9ad0ff;
    background-color:#3ca4ff;
    color: #d9edff;
    cursor: pointer;
    box-shadow: 0px 3px 7px rgb(139, 139, 139);
}

@media screen and (max-width: 800px) {

    .product_card {
        width: 90%;
        height: 110%;
        margin-bottom: 5vh;
    }

    .product_c_image {
        width: 95%;
        object-fit: cover;
        margin-top: -5%;
        margin-bottom: -18%;
    }

    .product_text {
        font-size: 1em;
    }

    .product_add {
        width: 50%;
        font-size: 1em;
    }
}