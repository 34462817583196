.product_detail_background {
    position: relative;
    background-color: rgb(240, 240, 240);
    height: 120ch;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

button:hover {
    background-color: yellow;
    color: rgb(92, 164, 176);
}

.detail_image {
    position: relative;
    cursor: pointer;
    width: 68%;
    transition: 200ms;
}

.detail_image:hover {
    outline: solid yellow;
    border-radius: 1em;
}

.product_detail_remove {
    position: relative;
    margin-left: 10%;
    width: 50%;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    background-color:rgb(112, 112, 112);
    box-shadow: 0px 2px 10px rgb(168, 168, 168)
}

.product_detail {
    position: relative;
    margin-left: 10%;
    width: 50%;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    background-color:rgb(220, 220, 220);
    box-shadow: 0px 2px 10px rgb(168, 168, 168)
}

.in_cart_detail {
    position: absolute;
    left: 16.5vw;
    top:27%;
    color: #ffe100;
    background-color: #000000;
    padding:1%;
    font-size: 400%;
    cursor: pointer;
    z-index: 99 !important;
}

.product_price {
    font-size: 175%;
    background-color: #84919c;
    width:30%;
    margin: auto;
}

.button_remove_cart_detail {
    border-radius: 15px;
    font-size: 20px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(0, 0, 0);
    color: #ffe100;
    cursor: pointer;
}

.product_detail:hover {
    transition-duration: .5s;
    background-color: rgb(240, 240, 240);
}

.product_detail_h {
    color:#3b4b5a;
    font-size: 2em;
    margin-bottom: 5px;
    margin-top: 10px;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.button_add_cart_detail {
    border-radius: 15px;
    font-size: 20px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.detail_review_holder {
    position: relative;
    z-index: 89;
    text-align: center;
    width:30vw;
    left: 61%;
    top:-84%;
    height: 31%;
    background-color: #dbdbdb;
    box-shadow: 0px 2px 10px rgb(168, 168, 168)
}

.detail_review_holder:hover {
    transition-duration: .5s;
    background-color: rgb(240, 240, 240);
}

.button_alt {
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.button_post_review {
    position: absolute;
    border-radius: 15px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 7%;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.product_review_h {
    color:#3b4b5a;
    font-size: 2em;
    margin-bottom: 1px;
    margin-top:-25px;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.button_review_detail {
    border-radius: 15px;
    font-size: 20px;
    padding: 6px;
    letter-spacing: .5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    position: absolute;
    width:25%;
    left: 63.5%;
    top:8%;
    height: 4%;
    transition: .2s;
}

.button_review_detail:hover{
    height: 6%;
}

.star_rating {
    position: relative;
    left: 7%;
    margin-top: -1em;
    width: 50%;
    right: 11%;
}

.rating_text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
}

.star {
    font-size: 1.8em;
}

.on {
    color: rgb(0, 0, 255);
}

.off {
    color: rgb(114, 114, 114);
}

.button_remove_review {
    border-radius: 3px;
    letter-spacing: .5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 2px;
    margin-top: 15px;
    border: 0ch;
    background-color: rgb(92, 164, 176);
    color: aliceblue;
    cursor: pointer;
}

.detail_reviews_list {
    position: absolute;
    height:51.5%;
    width:30%;
    left: 61%;
    top:33%;
    margin: auto;
    display: grid;
    row-gap: 20px;
    grid-template-columns: auto;
    overflow:auto;
    background-color:rgb(220, 220, 220);
    box-shadow: 0px 2px 10px rgb(168, 168, 168)
}

.detail_reviews_list:hover {
    transition-duration: .5s;
    background-color: rgb(240, 240, 240);
}

.user_reviews {
    display: inline-block;
    line-height: 25px;
    margin: auto;
    font-size: 23px;
    color:#3b4b5a;
    background-color:#c0c0c0;
    padding: 10px;
    border-radius: 20px;
}

.no_history_text {
    color:rgb(123, 123, 123);
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-left: 29%;
}

@media screen and (max-width: 1600px) {

    .detail_review_holder {
        position: absolute;
        z-index: 110 !important;
        text-align: center;
        width:100%;
        left: 0;
        top:0%;
        height: 101vh;
    }

    .star_rating {
        position: relative;
        top: -8%;
        left: 35%;
        margin-top: -1em;
        width: 20%;
    }

    .button_post_review {
        position: relative;
        margin-left: 12%;
        margin-bottom: 20px;
        margin-top: 4%;
    }

}

@media screen and (max-width: 1200px) {

    .product_detail {
        right: 10%;
        width: 60vw;
        height: 100vh;
    }

    .detail_image {
        width: 100%;
    }

    .product_price {
        font-size: 6vw;
        background-color: #84919c;
        width:80%;
        margin: auto;
    }

    .in_cart_detail {
        position: absolute;
        left: -2%;
        width: 60vw;
        top:25%;
        font-size: 200%;
        z-index: 99 !important;
    }

    .product_detail_remove {
        position: relative;
        left: -10%;
        width: 60%;
        height: 100vh;
        font-size: 5vw;
        font-weight: 600;
        background-color:rgb(112, 112, 112);
        box-shadow: 0px 2px 10px rgb(168, 168, 168)
    }

    .button_review_detail {
        position: relative;
        width:38%;
        height: 10%;
        left: 62%;
        top:-78.5%;
        font-size: 1.2em;
    }

    .detail_reviews_list {
        position: relative;
        height:80vh;
        width:38vw;
        left: 30%;
        top:-80%;
        text-align: center;
        box-shadow: 0
    }

    .no_history_text {
        font-size: 8vw;
        font-weight: 700;
        margin-left: 0;
    }

    .detail_review_holder {
        position: absolute;
        z-index: 110 !important;
        text-align: center;
        width:100vw;
        left: 0;
        top:-7.5%;
        height: 101vh;
    }

    .star_rating {
        position: relative;
        top: -20%;
        left: 25%;
        margin-top: -1em;
        width: 50%;
    }

    .button_post_review {
        position: relative;
        margin-left: 0;
        margin-bottom: 20px;
        margin-top: 30%;
    }

    .user_reviews {
        width: 30vw;
        line-height: 1em;
        margin: auto;
        font-size: 1.2em;
    }

    .star {
        font-size: 1.2em;
    }

}